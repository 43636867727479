// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-courses-page-v-2-js": () => import("./../../../src/templates/courses-page-v2.js" /* webpackChunkName: "component---src-templates-courses-page-v-2-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/default-page.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-englisch-beruf-js": () => import("./../../../src/templates/englisch-beruf.js" /* webpackChunkName: "component---src-templates-englisch-beruf-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-language-page-js": () => import("./../../../src/templates/language-page.js" /* webpackChunkName: "component---src-templates-language-page-js" */),
  "component---src-templates-language-page-v-2-js": () => import("./../../../src/templates/language-page-v2.js" /* webpackChunkName: "component---src-templates-language-page-v-2-js" */)
}

